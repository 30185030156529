import React, { ReactText, useCallback, useEffect, useState } from 'react';
import styles from './index.module.less';
import Arrow from '@/components/Arrow';
import { useVisible } from '@/constants/hooks/useVisible';

interface SelectOption {
  value: ReactText;
  text: string;

  [key: string]: ReactText;
}

interface OwnProps {
  options: SelectOption[];
  onSelect: (value: any, option?: SelectOption) => void;
  value: ReactText;
}

type Props = OwnProps;

const Selector = (props: Props) => {
  const { options, onSelect, value } = props;
  const [selectedText, setSelectedText] = useState<ReactText | undefined>();
  const { visible, onShow, onHide } = useVisible();

  useEffect(() => {
    const option = options.find(item => item.value === value);
    setSelectedText(option?.text);
  }, [options, value]);

  const toggleSelect = useCallback(() => {
    if (visible) {
      onHide();
    } else {
      onShow();
    }
  }, [visible, onHide, onShow]);

  const handleSelect = useCallback((value: ReactText, option?: SelectOption) => {
    onSelect(value, option);
    toggleSelect();
  }, [onSelect, toggleSelect]);

  return (
    <>
      <div className={ styles.selector }>
        <div className={ styles.select } onClick={ toggleSelect }>
          <span className={ styles.value }>{ selectedText }</span>
          <Arrow deg={ -135 }/>
        </div>
        <div className={ [styles.optionWrapper, visible ? styles.active : ''].join(' ') }>
          <div className={ styles.bulge }/>
          { options.map(item => (
            <div
              key={ item.value }
              onClick={ () => handleSelect(item.value, item) }
              className={ styles.option }
            >
              <span className={ [styles.optionText, value === item.value ? styles.optionActive : ''].join(' ') }>
                { item.text }
              </span>
            </div>
          )) }
        </div>
      </div>
    </>
  );
};

export default Selector;
