export enum route {
  /** 首页 */
  HOME = '/',
  /** 课程体系 */
  CURRICULUM = '/curriculum',
  /** 软件下载 */
  DOWNLOAD = '/download',
  /** 关于我们 */
  ABOUT = '/about',
  /** 常见问题 */
  QUESTION = '/question',
  /** 个人信息保护政策 */
  PRIVACY = '/privacy',
  /** 用户协议 */
  CONTRACT = '/contract',
  /** 营业执照 */
  LICENCE = '/licence',
  /** icp */
  ICP = '/icp',
  /** 购买页 */
  PURCHASE = '/purchase/:id/:sourceFlag',
  /** 教师公示页 */
  TEACHER_INFO = '/teacher-info',
  /** 不匹配 */
  MISMATCH = '/*',
}
