import React, { useReducer } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { route } from './constants/route';
import asyncComponent from './utils/asyncComponent';
import { AppContext, initialState, mainReducer } from '@/store';

const { Provider } = AppContext;

const Home = asyncComponent(() => import(/* webpackChunkName: "Home" */ './containers/Home'));
const Curriculum = asyncComponent(() => import(/* webpackChunkName: "Curriculum" */ './containers/Curriculum'));
const Download = asyncComponent(() => import(/* webpackChunkName: "Download" */ './containers/Download'));
const About = asyncComponent(() => import(/* webpackChunkName: "About" */ './containers/About'));
const Question = asyncComponent(() => import(/* webpackChunkName: "Question" */ './containers/Question'));
const Purchase = asyncComponent(() => import(/* webpackChunkName: "Purchase" */ './containers/Purchase'));
const TeacherInfo = asyncComponent(() => import(/* webpackChunkName: "Purchase" */ './containers/TeacherInfo'));
const Privacy = asyncComponent(() => import(/* webpackChunkName: "Privacy" */ './containers/Rights/Privacy'));
// const Contract = asyncComponent(() => import(/* webpackChunkName: "Contract" */ './containers/Rights/Contract'));
const Licence = asyncComponent(() => import(/* webpackChunkName: "Licence" */ './containers/Rights/Licence'));

const App = () => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  return (
    <div className="App">
      <Provider value={{ state, dispatch }}>
        <Router>
          <Route exact component={Home} path={route.HOME}/>
          <Route exact component={Curriculum} path={route.CURRICULUM}/>
          <Route exact component={Download} path={route.DOWNLOAD}/>
          <Route exact component={About} path={route.ABOUT}/>
          <Route exact component={Question} path={route.QUESTION}/>
          <Route exact component={Privacy} path={route.PRIVACY}/>
          {/*<Route exact component={Contract} path={route.CONTRACT}/>*/}
          <Route exact component={Licence} path={route.LICENCE}/>
          <Route exact component={Purchase} path={route.PURCHASE}/>
          <Route exact component={TeacherInfo} path={route.TEACHER_INFO}/>
        </Router>
      </Provider>
    </div>
  );
};

export default App;
