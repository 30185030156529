import { Country } from '@/components/formItem/formProMobile';
import { ActionType } from '@/store/actions';

type CountryType = Country;
export type CountryPayload = Country;

export const countryReducer = (state: CountryType, action: { type: string; payload: CountryPayload }): CountryType => {
  switch (action.type) {
  case ActionType.changeCountryCode:
    return action.payload;

  default:
    return state;
  }
};
