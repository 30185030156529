// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ActionMap<M extends { [index: string]: any }> = {
  [key in keyof M]: M[key] extends undefined
    ? { type: key }
    : {
      type: key;
      payload: M[key];
    }
}

export enum ActionType {
  changeCountryCode = 'CHANGE_COUNTRY_CODE',
  updateSourceCode = 'UPDATE_SOURCE_CODE'
}
