import React, { useEffect, useState } from 'react';
import styles from './index.module.less';

interface OwnProps {
  type?: 'solid' | 'line';
  size?: number;
  deg?: number;
  color?: string;
}

type Props = OwnProps;

const Arrow = (props: Props) => {
  const { type, size, deg, color } = props;
  const [defaultType, setDefaultType] = useState('solid');

  useEffect(() => {
    if (type) {
      setDefaultType(type);
    }
  }, [type]);

  if (defaultType === 'solid') {
    return (
      <div
        className={ styles.solidArrow }
        style={ {
          borderLeftColor: color || '#888',
          borderTopColor: color || '#888',
          transform: `rotate(${ deg }deg)`,
          width: size || '10px',
          height: size || '10px',
        } }
      />
    );
  }
  if (defaultType === 'line') {
    return (
      <div className={ styles.lineArrowWrapper }>
        <div
          className={ styles.lineArrow }
        />
      </div>
    );
  }
  return null;
};

export default Arrow;
