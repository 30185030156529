import * as React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonLoader: React.FC = () => {
  return (
    <>
      <ContentLoader viewBox={'0 0 1920 90'} backgroundColor={'rgba(35, 163, 255, .6)'}>
        <rect x="0" y="0" rx="0" ry="0" width="1920" height="90"/>
      </ContentLoader>
      <div style={{marginTop: -10}}>
        <ContentLoader viewBox={'0 0 1920 10700'}>
          <rect x="0" y="0" rx="0" ry="0" width="1920" height="600"/>
          <rect x="360" y="640" rx="50" ry="50" width="1200" height="5000"/>
        </ContentLoader>
      </div>
    </>
  );
};

interface AsyncComponentState {
  Component: any;
}

const asyncComponent = (importComponent: any): any => {
  class AsyncComponent extends React.Component<any, AsyncComponentState> {
    constructor(props: any) {
      super(props);
      this.state = {
        Component: null
      };
    }

    async componentDidMount() {
      const {default: Component} = await importComponent();
      this.setState({
        Component: Component
      });
    }
    render() {
      const C = this.state.Component;
      return C ? <C {...this.props}/> : <SkeletonLoader/>;
    }
  }

  return AsyncComponent;
};

export default asyncComponent;
