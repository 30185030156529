import { useCallback, useState } from 'react';

export const useVisible = (initialVisible = false) => {
  const [visible, setVisible] = useState(initialVisible);

  const onShow = useCallback(() => {
    setVisible(true);
  }, []);

  const onHide = useCallback(() => {
    setVisible(false);
  }, []);

  return {visible, onShow, onHide};
};
