import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import styles from './index.module.less';
import Selector from '@/components/Selector';

export enum Country {
  CN = 'CN',
  HK = 'HK',
  MO = 'MO',
  TW = 'TW',
}

enum CountryValue {
  CN = '86',
  HK = '852',
  MO = '853',
  TW = '886',
}

const countryOption = [
  {
    value: CountryValue.CN,
    text: `+${ CountryValue.CN }`,
    countryCode: Country.CN,
  },
  {
    value: CountryValue.HK,
    text: `+${ CountryValue.HK }`,
    countryCode: Country.HK,
  },
  {
    value: CountryValue.MO,
    text: `+${ CountryValue.MO }`,
    countryCode: Country.MO,
  },
  {
    value: CountryValue.TW,
    text: `+${ CountryValue.TW }`,
    countryCode: Country.TW,
  },

];

interface OwnProps {
  value: string;
  onChange: (mobile: string, countryCode: Country) => void;
  onSelectChange: (countCode: Country) => void;
  placeholder: string;
  defaultCountryCode: Country;
}

type Props = OwnProps;

const FormProMobile = (props: Props) => {
  const { value, onChange, onSelectChange, placeholder, defaultCountryCode } = props;
  const [selectedCountryNumber, setSelectedCountryNumber] = useState<CountryValue>(CountryValue.CN);
  const [countryCode, setCountryCode] = useState<Country>(defaultCountryCode);

  useEffect(() => {
    setCountryCode(defaultCountryCode);
    const option = countryOption.find(item => item.countryCode === defaultCountryCode);
    if (option && option.value) {
      setSelectedCountryNumber(option.value);
    }
  }, [defaultCountryCode]);

  const handleSelect = useCallback((values: CountryValue, option) => {
    setSelectedCountryNumber(values);
    setCountryCode(option?.countryCode);
    onSelectChange(option?.countryCode);
  }, [onSelectChange]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const input = e.target.value;
    // eslint-disable-next-line default-case
    switch (countryCode) {
    case Country.CN:
      if (input.length > 11) {
        break;
      } else {
        onChange(e.target.value, countryCode);
        break;
      }
    case Country.HK:
    case Country.MO:
      if (input.length > 8) {
        break;
      } else {
        onChange(e.target.value, countryCode);
        break;
      }
    case Country.TW:
      if (input.length > 10) {
        break;
      } else {
        onChange(e.target.value, countryCode);
        break;
      }
    }

  }, [onChange, countryCode]);

  return (
    <>
      <div className={ styles.formPhoneInput }>
        <Selector
          options={ countryOption }
          onSelect={ handleSelect }
          value={ selectedCountryNumber }
        />
        <input
          type="text"
          placeholder={ placeholder }
          value={ value }
          onChange={ handleChange }
        />
      </div>
    </>
  );
};

export default FormProMobile;
