import { ActionType } from '@/store/actions';

type SourceType = string;
export type SourcePayload = string;

export const sourceReducer = (state: SourceType, action: { type: string; payload: SourcePayload }): SourceType => {
  switch (action.type) {
  case ActionType.updateSourceCode:
    return action.payload;

  default:
    return state;
  }
};
