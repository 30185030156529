import { Country } from '@/components/formItem/formProMobile';
import { createContext, Dispatch } from 'react';
import { CountryPayload, countryReducer } from '@/store/reducers/country';
import { SourcePayload, sourceReducer } from '@/store/reducers/source';
import { ActionType } from '@/store/actions';


export type State = {
  countryCode: Country;
  s: string;
};

export const initialState: State = {
  countryCode: Country.CN,
  s: '',
};

type Payload = CountryPayload | SourcePayload;

export const AppContext = createContext<{
  state: State,
  dispatch: Dispatch<{
    type: ActionType;
    payload: Payload;
  }>
}>({
  state: initialState,
  dispatch: () => null,
});

export const mainReducer = (state: State, action: { type: ActionType, payload: any }) => {
  return {
    countryCode: countryReducer(state.countryCode, action),
    s: sourceReducer(state.s, action),
  };
};

